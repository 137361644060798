.dropdown-label {
  display: block;
  color: rgba(32, 14, 50, 0.5);
  font-size: 17px;
}

.ant-select {
  border: 0.2px solid rgba(25, 0, 65, 0.26) !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  height: 45px !important;
  font-size: 15px !important;
  margin-top: 10px !important;
  margin-bottom: 30px !important;
  outline: none !important;
}

.ant-select-borderless.unique-borderless {
  border-radius: 0px !important;
  background-color: #fff !important;
  height: 45px !important;
  font-size: 15px !important;
  margin-top: 10px !important;
  margin-bottom: 30px !important;
  outline: none !important;
  border: none !important;
  border-bottom: 2px solid rgba(25, 0, 65, 0.26) !important;
  width: 500px;
}

.has-error-1 {
  border: 1px solid red !important;
}

.ant-select-selector:focus {
  border: #ab3882 !important;
}

/* .my-select-container .ant-select .ant-select-selector {
  border-radius: 100px;
} */

@media screen and (max-width: 768px) {
  .dropdown-container {
    width: 100%;
  }
}
